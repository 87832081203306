import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import "./tasks.css";
import ruby from "images/ruby.png";
import ticket from "images/ticket.svg";
import frog from "images/frog.png";
import coin from "images/coin.png";
import arrow from "images/icons/arrow.svg";
import clock from "images/icons/clock.svg";
import doneTask from "images/icons/done-task.svg";
import cup from "images/icons/nav-bar/game.svg";
import frormatNumber from "utils/frormat-number";
import Button from "UI/Button";
import getTasks from "api/get-tasks";
import tg from "utils/tg";
import taskDone from "api/task-done";
import AppContext from "utils/app-context";
import TasksInfo from "types/tasks-info";
import claimDailyTask from "api/claim-daily-task";
import claimDailyQuest from "api/claim-daily-quest";
type Props = {};

const Tasks = (props: Props) => {
  const [currentPage, setcurrentPage] = useState(0)
  const [tasks, settasks] = useState<TasksInfo | null>(null)
  const {user,updateUserInfo} = useContext(AppContext)
  const updateTasks = async () => {
    const result = await getTasks()
    if (result) settasks(result)
  }

  const doneTasks = tasks?.tasks.filter(({status}) => status)
  const notComplitedTasks = tasks?.tasks.filter(({status}) => !status)

  const completeTask = (task_id:string,urL:string) => {
    try {
      tg.openTelegramLink(urL);
    } catch (error) {
      tg.openLink(urL);
    }

    setTimeout(async () => {
      const result = await taskDone(task_id)
      updateTasks()
    },1000)
  }

  const claimDailyTaskHandle = async () => {
    const result = await claimDailyTask()
    console.log("🚀 ~ claimDailyTaskHandle ~ result:", result)
    updateTasks()
    updateUserInfo()

  }

  const claimDailyQuestHandle = async (subtask_id:string | number) => {
    const result = await claimDailyQuest(subtask_id)
    console.log("🚀 ~ claimDailyQuestHandle ~ result:", result)
    updateTasks()
    updateUserInfo()
  }

  useEffect(() => {
    updateTasks()
  }, [])
  return (
    <PageWrapper className="tasks">
      <h1>Tasks</h1>
      <p className="tasks-page-desc">Complete tasks and get bonuses.</p>
      <ul className="user-balance-list">
        <li className="user-balance-list-item glass-bg">
          <img src={ruby} alt="" width={24} height={24} />
          <span>{frormatNumber(user?.diamonds || 0)}</span>
        </li>
       <li className="user-balance-list-item glass-bg">
          <img src={ticket} alt="" height={24}/>
          <span>{frormatNumber(user?.tickets || 0)}</span>
        </li>
       <li className="user-balance-list-item glass-bg">
          <img src={coin} alt="" width={24} height={24}/>
          <span>{frormatNumber(user?.balance || 0)}</span>
        </li>
      </ul>
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button 
          active={currentPage === 0} 
          onClick={() => setcurrentPage(0)}
          text="Daily Quests" />
        </li>
        <li className="store-categories-item">
          <Button 
          active={currentPage === 1} 
          onClick={() => setcurrentPage(1)}
          text="Tasks" />
        </li>
        <li className="store-categories-item">
          <Button 
          active={currentPage === 2} 
          onClick={() => setcurrentPage(2)}
          text="Done" />
        </li>
      </ul>
      {currentPage === 0 && 
        <>
        <div className="daily-task-container glass-bg">
        <div className="daily-task-header">
          <h2>Daily Task</h2>
          <div className="daily-task-header-day-count glass-bg">Day {tasks?.daily_task.day}</div>
        </div>
        <ul className="daily-task-progress">
          {tasks?.daily_task.rewards.map((r,i,arr) => {
            const isfisrst = i === 0
            return (
              <>
              {!isfisrst && <li className={`${i+1 <= tasks.daily_task.day && 'green-bg'} daily-task-progress-line`} />}
              <li className={`${i+1 <= tasks.daily_task.day && 'green-bg'} daily-task-progress-cicrle`} />
              </>
            )
          })}
        </ul>
        <ul className="daily-task-days-progress">
          {tasks?.daily_task.rewards.map((r,i,arr) => {
            return (
              <li className="daily-task-day" key={i}>
              <h5>Day {i+1}</h5>
              <img src={coin} alt="coin-image" width={12} height={12} />
              <p>{r}</p>
            </li>
            )
          })}
        </ul>
        <div className="login-reward glass-bg">
          <div className="login-reward-text">
            <img src={cup} width={24} height={24} />
            <p>Log in to the game</p>
          </div>
         {tasks?.daily_task.claim_is_active
         ? <Button active onClick={claimDailyTaskHandle}>
            <p className="btn-text">
            Claim
            </p>
            <img src={coin} width={24} height={24} />
            <span>+ {tasks?.daily_task.rewards[tasks?.daily_task.day-1]}</span>
          </Button>
          :  <div className="login-reward-non-active">
             <img src={coin} width={24} height={24} />
             <span>+ 60</span>
             <img src={clock} width={35} height={35} />

          </div>
        }
        </div>
      </div>
      <div className="daily-quest-container glass-bg">
        <div className="daily-quest-container-header">
          <div className="daily-quest-container-header-desc">
            <img src={`./memes/${tasks?.daily_quest.mem}`} width={40} height={40} />
            <p>{tasks?.daily_quest.title}</p>
          </div>
          <p className="btn-text">
          Daily Quest
          </p>
        </div>
        <ul className="daily-quest-list">
          {tasks?.daily_quest.subtasks.map(({icon,step,steps,title,id,claim_is_active,reward}) => (
            <li className="daily-quest-item glass-bg">
            <div className="daily-quest-item-desc">
              <span className="daily-quest-item-circle">{icon}</span>
              <p>{title}</p>
            </div>
           {claim_is_active
            ?<Button active onClick={() => claimDailyQuestHandle(id)} className="daily-quest-item-btn">
                <p className="btn-text">
                Claim
                </p>
                <img src={coin} width={24} height={24} />
                <span>+ {reward}</span>
              </Button>
           : <div className="daily-quest-item-reward">
              <div className="daily-quest-item-count-progress glass-bg">
              {step}/{steps}
              </div>
            <img src={coin} width={24} height={24} />
              <p>+ 25</p>
            </div>}
          </li>
          ))}
        </ul>
      </div>
        </>
      }
      {currentPage === 1 && 
        <ul className="task-list">
          {notComplitedTasks && notComplitedTasks.map(({reward,title,id,url,steps,step}) => {
            return (
              <li key={id} className="task-card glass-bg" onClick={() => completeTask(id,url)}>
                <div className="task-card-desc">
                  <img src="./img/task-icons/energy.svg" alt="" />
                  <p>{title}</p>
                </div>
                <div className="task-card-reward">
                  {steps && <div className="daily-quest-item-count-progress glass-bg">
                  {step}/{steps}
                  </div>}
                  <img src={coin} width={24} height={24} />
                  <p> + {frormatNumber(reward)}</p>
                  <img src={arrow} width={24} height={24} />
                </div>
              </li>
            )
          })}
        </ul>
      }
       {currentPage === 2 && 
        <ul className="task-list">
          {doneTasks && doneTasks.map(({reward,title,id,step,steps}) => {
            return (
              <li className="task-card glass-bg" key={id}>
                <div className="task-card-desc">
                  <img src="./img/task-icons/energy.svg" alt="" />
                  <p>{title}</p>
                </div>
                <div className="task-card-reward">
                  {steps && <div className="daily-quest-item-count-progress glass-bg">
                  {step}/{steps}
                  </div>}
                  <img src={coin} width={24} height={24} />
                  <p> + {frormatNumber(reward)}</p>
                  <img src={doneTask} width={24} height={24} />
                </div>
              </li>
            )
          })}
        </ul>
      }
    </PageWrapper>
  );
};

export default Tasks;
