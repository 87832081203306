import React, { useContext } from "react";
import "./squad.css";
import coin from "images/coin.png";
import info from "images/icons/info.svg";
import userIcon from "images/icons/user-filled.svg";
import PageWrapper from "UI/PageWrapper";
import frormatNumber from "utils/frormat-number";
import Button from "UI/Button";
import { ModalContext } from "hooks/use-modal";
import AppContext from "utils/app-context";
import referalClaim from "api/referal-claim";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''

const Squad = () => {
  const {setModal} = useContext(ModalContext)
  const {user,updateUserInfo} = useContext(AppContext)

  const handleReferalClaim = async () => {
    const result = await referalClaim()
    console.log("🚀 ~ handleReferalClaim ~ result:", result)
    updateUserInfo()
  } 

  const copyShareLink = (e:React.MouseEvent) => {
    e.currentTarget.textContent = 'The link is copied ✅'
    navigator.clipboard.writeText(inviteLink)
}
const TelegramShare = () => {
    tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${process.env.REACT_APP_SHARE_TEXT}`)
}

  return (
    <PageWrapper className="squad-page">
      <div className="squad-page-header">
        <div className="user-reward">
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>{(user?.referral_reward || 0) > 0 ? '+' : ''} {frormatNumber(user?.referral_reward || 0)}</span>
          <Button active text="Claim" style={{ padding: "auto 27px" }} onClick={handleReferalClaim}/>
        </div>

        <img src={info} width={21} height={21} 
          onClick={() => setModal({text:'0-10 invited friends – 50 points for a friend.\n11-110 invited friends – 100 points for a friend.\n101-500 invited friends – 250 points for a friend.',title:'Information'})}
        />
      </div>
      <ul className="squad-page-user-stats">
        <li className="user-stat glass-bg">
          <div
            className="user-stat-progress-bar green-bg"
            style={{ width: "30%" }}
          ></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>50</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>

        <li className="user-stat glass-bg">
          <div className="user-stat-progress-bar green-bg"></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>100</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>

        <li className="user-stat glass-bg">
          <div className="user-stat-progress-bar green-bg"></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>150</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>
      </ul>
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button text="Friends" active />
        </li>
        <li className="store-categories-item">
          <Button text="Squad" disabled />
        </li>
      </ul>
      <div className="friends-list-container">
        <p className="friends-counter">Friends ({user?.friends}):</p>
        <ul className="friends-list">
          {user?.friends_list && user.friends_list.map(({coins,name,photo}) => (
              <li className="friends-list-item glass-bg">
              <div className="friends-list-item-info">
                <img src={photo} className="friends-list-item-image" alt="" />
                <p>{name}</p>
              </div>
              <div className="friends-list-item-reward">
                <img src={coin} alt="coin-image "width={24} height={24} />
                <span>+ {frormatNumber(coins)}</span>
              </div>
              </li>
          ))}
        </ul>
      </div>
      <div className="referal-buttons">
      <Button text="Copy Link" onClick={copyShareLink}/>
      <Button active text="Invite Friend" onClick={TelegramShare}/>
      </div>
    </PageWrapper>
  );
};

export default Squad;
