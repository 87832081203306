import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import routes from "./routes";
import NavigationBar from "UI/NavigationBar";
import Modal from "UI/Modal";
import useModal from "hooks/use-modal";
import getToken from "api/get-token";
import useAppData from "hooks/use-app-data";

function App() {
  const location = useLocation();
  const ModalContext = useModal();
  const AppContext = useAppData()
  const element = useRoutes(routes.map(({ route }) => route));

  if (!element) return null;
  return (
    <AppContext>
    <ModalContext>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>
        <NavigationBar />
        <Modal />
    </ModalContext>
    </AppContext>
  );
}

export default App;
