import React from "react";
import coin from "images/coin.png";
import greenStar from "images/green-star.png";
import "./rating.css";
import PageWrapper from "UI/PageWrapper";
import Button from "UI/Button";
import frormatNumber from "utils/frormat-number";
type Props = {};

const Rating = (props: Props) => {
  const limit = 20

  const ratingItems = []

  for (let i = 0; i < limit; i++) {
    ratingItems.push({
      image:"./img/friend-placeholder-2.png",
      name:'Name',
      coins:3456234,
      stars:20456234
    })
  }
  
  return (
    <PageWrapper className="rating coming-soon">
      <h1>Rating</h1>
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button active text="Personal" />
        </li>
        <li className="store-categories-item">
          <Button text="Global" />
        </li>
      </ul>
      <ul className="friends-list rating-list">
        {ratingItems.map(({coins,image,name,stars},i) => (
          <li className="friends-list-item glass-bg rating-list-item">
          <div className="friends-list-item-info">
            <span className="rating-position">{i+1}</span>
            <img
              className="friends-list-item-image"
              src={image}
            />
            <div className="rating-stats-block">
              <p>{name}</p>
              <div className="rating-stats">
                <img src={greenStar} height={24} width={24} alt="" />
                <span>{frormatNumber(stars)}</span>
              </div>
            </div>
          </div>
          <div className="friends-list-item-reward">
            <img src={coin} alt="coin-image " width={24} height={24} />
            <span>{frormatNumber(coins)}</span>
          </div>
        </li>
        ))}
      </ul>
    </PageWrapper>
  );
};

export default Rating;
