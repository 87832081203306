import { createContext, ReactNode, useEffect, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import getToken from "api/get-token";

type MyProviderProps = {
    children: ReactNode;
  };
export default ():React.FC<MyProviderProps> => {
    const [user, setUser] = useState<UserData | null>(null)
    console.log("🚀 ~ user:", user)

    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result) setUser(result)
    }

    const AppContextValue:AppContextType = {
        user,
        updateUserInfo,
        setUser
    }

    useEffect(() => {
        updateUserInfo()
    }, [])
    useEffect(() => {
        if (user && !user.token) {
            getToken().then(result => {
                if (result?.result) {
                    document.cookie = `token=${result.data.token}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`
                    if(user) setUser({...user,token:result.data.token})
                }
            })
       
        }
    }, [user])
    return ({children}) => <AppContext.Provider value={AppContextValue}>
        {children}
    </AppContext.Provider>
}